export const HRLAB_THEME = {
  palette: {
    primary: {
      light: "#E6F5FC",
      main: "#0075B2",
      dark: "#015293",
    },
    secondary: {
      light: "#DDF1F0",
      main: "#26B2A4",
      dark: "#006452",
    },
    success: {
      light: "#D4F7D2",
      main: "#07BC0C",
      dark: "#048308",
    },
    error: {
      light: "#FCDAD7",
      main: "#E74C3C",
      dark: "#A1352A",
    },
    warning: {
      light: "#FFEAD1",
      main: "#FF8800",
      dark: "#F36C01",
    },
    info: {
      light: "#33B1E0",
      main: "#009ED9",
      dark: "#006E97",
    },
    common: {
      white: "#FFFFFF",
      black: "#00111A",
    },
    grey: {
      50: "#F8F9FA",
      100: "#F1F2F3",
      200: "#E8E9EA",
      300: "#D8D9DA",
      400: "#B4B5B6",
      500: "#949596",
      600: "#6C6D6E",
      700: "#59595A",
      800: "#3A3B3C",
      900: "#1A1B1B",
    },
  },
};
