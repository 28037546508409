module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-multi-language/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"pricing":"pricing","blog":"blog","categories":"categories","about-us":"about-us","faq":"faq","imprint":"imprint","privacy-terms":"privacy-terms","features":"features","newsletter":"newsletter","confirmation":"confirmation","hr-lexicon":"hr-lexicon","ticket-confirmation":"ticket-confirmation","webdemo-booked-successfully":"webdemo-booked-successfully","registration-successful":"registration-successful","submission-successful":"submission-successful","partners":"partners","success-stories":"success-stories","industries":"industries"},"de":{"pricing":"preise","blog":"blog","categories":"kategorien","about-us":"ueber-uns","faq":"faq","imprint":"impressum","privacy-terms":"datenschutzerklaerung","features":"funktionen","newsletter":"newsletter","confirmation":"bestaetigung","hr-lexicon":"hr-lexikon","ticket-confirmation":"ticket-bestaetigung","webdemo-booked-successfully":"webdemo-buchung-erfolgreich","registration-successful":"registrierung-erfolgreich","submission-successful":"einsendung-erfolgreich","partners":"partner","success-stories":"erfolgsgeschichten","industries":"branchen"}},"defaultLocale":"de","prefix":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T6HMDGN","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HRlab","short_name":"HRlab","description":"Die flexible All-in-One HR Software für den modernen Mittelstand.","lang":"de","display":"standalone","icon":"static/hrlab-submark.png","start_url":"/","orientation":"natural","background_color":"#ffffff","theme_color":"#ffffff","localize":[{"start_url":"/en","lang":"en","name":"HRlab","short_name":"HRlab","description":"Flexible All-in-One HR Software For Medium-Sized Companies."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d831556196c0bef0cabf06847b9adfd3"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
