import * as React from "react";
import isClient from "../utils/isClient";
import { triggerDataLayerEvent } from "../utils/googleHelpers";
import { useCookie } from "../hooks/useCookie";
import { useAllStrapiCookie } from "../hooks/useAllStrapiCookie";

const COOKIE_CONFIG = {
  name: "GDPR",
  expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toGMTString(),
  path: "/",
};

export const GDPRContext = React.createContext();

const GDPRContextProvider = ({ children }) => {
  const cookies = useAllStrapiCookie("de");

  const reducer = (state, action) => {
    switch (action.type) {
      case "all":
        return cookies.map((item) => item.node.slug);
      case "individual":
        return action.payload;
      case "delete":
        return null;

      default:
        return state;
    }
  };

  const [cookie, setCookie] = useCookie(COOKIE_CONFIG);
  const [consent, dispatch] = React.useReducer(reducer, cookie || null);

  React.useEffect(() => {
    if (!isClient) return;
    if (!consent) return;

    consent?.forEach((item) => triggerDataLayerEvent(`gatsby-consent-${item}`));
    setCookie(consent);
  }, [consent, setCookie]);

  return <GDPRContext.Provider value={{ consent, dispatch }}>{children}</GDPRContext.Provider>;
};
export default GDPRContextProvider;
