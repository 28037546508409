import isClient from "./isClient";

/**
 * Pushes an event with a given name to the data layer. This can be used to trigger custom events in Google Tag Manager.
 * @param eventName name of the custom event
 */
export const triggerDataLayerEvent = (eventName) => {
  if (!isClient) return;
  if (!eventName) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventName });
};
