import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { HRLAB_THEME } from "./hrlabtheme";

// New Custom Theme for HrLab

/**
 * Material UI Custom Theme for HRlab
 */
let theme = createTheme({
  palette: {
    primary: {
      light: HRLAB_THEME.palette.primary.light,
      main: HRLAB_THEME.palette.primary.main,
      dark: HRLAB_THEME.palette.primary.dark,
    },
    secondary: {
      light: HRLAB_THEME.palette.secondary.light,
      main: HRLAB_THEME.palette.secondary.main,
      dark: HRLAB_THEME.palette.secondary.dark,
    },
    info: {
      light: HRLAB_THEME.palette.info.light,
      main: HRLAB_THEME.palette.info.main,
      dark: HRLAB_THEME.palette.info.dark,
      contrastText: "#fff",
    },

    warning: {
      light: HRLAB_THEME.palette.warning.light,
      main: HRLAB_THEME.palette.warning.main,
      dark: HRLAB_THEME.palette.warning.dark,
    },

    error: {
      light: HRLAB_THEME.palette.error.light,
      main: HRLAB_THEME.palette.error.main,
      dark: HRLAB_THEME.palette.error.dark,
    },

    success: {
      light: HRLAB_THEME.palette.success.light,
      main: HRLAB_THEME.palette.success.main,
      dark: HRLAB_THEME.palette.success.dark,
    },
    common: {
      white: HRLAB_THEME.palette.common.white,
      black: HRLAB_THEME.palette.common.black,
    },
    grey: {
      50: HRLAB_THEME.palette.grey["50"],
      100: HRLAB_THEME.palette.grey["100"],
      200: HRLAB_THEME.palette.grey["200"],
      300: HRLAB_THEME.palette.grey["300"],
      400: HRLAB_THEME.palette.grey["400"],
      500: HRLAB_THEME.palette.grey["500"],
      600: HRLAB_THEME.palette.grey["600"],
      700: HRLAB_THEME.palette.grey["700"],
      800: HRLAB_THEME.palette.grey["800"],
      900: HRLAB_THEME.palette.grey["900"],
    },
    text: {
      primary: HRLAB_THEME.palette.common.black,
    },
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      fontWeight: 700,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    button: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
      fontSize: "1.125rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "1rem",
    },
    body3: {
      fontWeight: 400,
      fontSize: "0.85rem",
    },
    overline: {
      fontWeight: 700,
      fontSize: "1rem",
      letterSpacing: "1px",
    },
    caption: {
      fontWeight: 400,
    },
    quote: {
      fontWeight: 400,
      fontSize: "1.5rem",
      letterSpacing: "1px",
    },
    mainNavItem: {
      fontWeight: 700,
      fontSize: "1.125rem",
    },
    mainNavSubItem: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "1rem",
    },
    author: {
      fontWeight: 700,
      fontSize: "1.1rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    spotlightLarge: {
      //same,replaced for h1
      fontSize: "3.75rem",
      "@media (max-width:1200px)": {
        // lg breakpoint
        fontSize: "3.5rem",
      },
      "@media (max-width:900px)": {
        // md breakpoint
        fontSize: "3.3rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "2.8rem",
      },
      fontWeight: 700,
      lineHeight: "1.2",
      letterSpacing: "-0.0075rem",
    },
    displayLarge: {
      //same,replaced for h1
      fontSize: "3rem",
      "@media (max-width:1200px)": {
        fontSize: "2.7849rem",
      },
      "@media (max-width:900px)": {
        fontSize: "2.5707rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2rem",
      },
      fontWeight: 700,
      lineHeight: "1.2",
      letterSpacing: "-0.0075rem",
    },
    displayMedium: {
      //same, replaced for h2
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: "1.2",
    },
    displaySmall: {
      //same, replaced for h3
      fontSize: "2.25rem",
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.8219rem",
      },

      fontWeight: 700,
      lineHeight: "1.2",
    },
    headlineLarge: {
      //same, replaced for h4
      fontSize: "2rem",
      "@media (max-width:1200px)": {
        // sm breakpoint
        fontSize: "1.9867remrem",
      },
      "@media (max-width:900px)": {
        // sm breakpoint
        fontSize: "1.8219rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.5625rem",
      },
      fontWeight: 700,
      lineHeight: "1.2",
    },
    headlineMedium: {
      //same, replaced for h5
      fontSize: "1.75rem",
      "@media (max-width:1400px)": {
        // sm breakpoint
        fontSize: "1.14993rem",
      },
      "@media (max-width:900px)": {
        // sm breakpoint
        fontSize: "1.3118rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.125rem",
      },
      fontWeight: 700,
      lineHeight: "1.2",
    },
    headlineSmall: {
      //same, replaced for h6
      fontSize: "1.625rem",
      "@media (max-width:1200px)": {
        // lg breakpoint
        fontSize: "1.4993rem",
      },
      "media (max-width:900px)": {
        fontSize: "1.4993rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.374rem",
      },
      fontWeight: 700,
      lineHeight: "1.2",
    },
    titleLarge: {
      //same
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "1.2",
    },
    titleMedium: {
      //same
      fontSize: "1.375rem",
      fontWeight: 700,
      lineHeight: "1.2",
    },
    titleSmall: {
      //tell Luca about breakpoint at 800
      //same
      fontSize: "1.25rem",
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.125rem",
      },
      lineHeight: "1.2",
      fontWeight: 700,
    },
    labelLarge: {
      //same
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
    },
    labelMedium: {
      //same
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.005rem",
      fontWeight: 700,
    },
    labelSmall: {
      //same
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.0075em",
      fontWeight: 700,
    },
    bodyLarge: {
      //same,  replace body 1
      fontSize: "1.125rem",
      "@media (max-width:1200px)": {
        // lg breakpoint
        fontSize: "1.1667rem",
      },

      fontWeight: 400,
      lineHeight: "1.75rem",
      letterSpacing: "0.00281rem",
    },
    bodyMedium: {
      //same, replace body 2
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      letterSpacing: "0.005rem",
    },
    bodySmall: {
      //same, replace body 3,dont need to be media query cause it doesnt change
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      letterSpacing: "0.00219rem",
    },
    micro: {
      //same
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "0.875rem",
    },
    overlineNew: {
      //same
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0.06rem",
      textTransform: "uppercase",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
      @font-face {
        font-family: "Inter";
        font-display: swap;
        font-style: normal;
        font-weight: 400;
        src: url(/fonts/Inter-Regular.woff2) format("truetype");
      }
      
      @font-face {
        font-family: "Inter";
        font-display: swap;
        font-style: normal;
        font-weight: 700;
        src: url(/fonts/Inter-Bold.woff2) format("truetype");
      }

      .MuiTypography-h1,
      .MuiTypography-h2,
      .MuiTypography-h3,
      .MuiTypography-h4,
      .MuiTypography-h5,
      .MuiTypography-h6,
      .MuiTypography-body1,
      .MuiTypography-bodyLarge,
      .MuiTypography-displayLarge,
      .MuiTypography-displayMedium,
      .MuiTypography-displaySmall,
      .MuiTypography-headlineLarge,
      .MuiTypography-headlineMedium,
      .MuiTypography-headlineSmall,
      .MuiTypography-titleSmall {
        word-wrap: break-word;
        hyphens: auto;
      }

      .MuiTypography-h1,
      .MuiTypography-h2,
      .MuiTypography-h3,
      .MuiTypography-h4,
      .MuiTypography-h5,
      .MuiTypography-h6, 
      .MuiTypography-displayLarge,
      .MuiTypography-displayMedium,
      .MuiTypography-displaySmall,
      .MuiTypography-headlineLarge,
      .MuiTypography-headlineMedium,
      .MuiTypography-headlineSmall,
      .MuiTypography-spotlightLarge,
      .MuiTypography-titleLarge,
      .MuiTypography-titleMedium,
      .MuiTypography-titleSmall { 
        background: linear-gradient(
          to top left,
          ${themeParam.palette.info.main} 0%,
          ${themeParam.palette.primary.main} 50%,
          ${themeParam.palette.primary.dark} 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }      
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          displayLarge: "h1",
          displayMedium: "h1",
          displaySmall: "h1",
          headlineLarge: "h2",
          headlineMedium: "h2",
          headlineSmall: "h2",
          titleLarge: "h3",
          titleMedium: "h3",
          titleSmall: "h3",
          labelLarge: "p",
          labelMedium: "p",
          labelSmall: "p",
          bodyLarge: "p",
          bodyMedium: "p",
          bodySmall: "p",
          overlineNew: "p",
          caption: "p",
        },
      },
    },
  },
});

// Applies calculated resizing for fonts on smaller screens. Check: https://mui.com/material-ui/customization/theming/#responsivefontsizes-theme-options-theme
theme = responsiveFontSizes(theme, { breakpoints: ["sm", "md", "lg"] });

export default theme;
