import * as React from "react";
const { translateUrl } = require("../utils/translate-url");

export const LocalizationContext = React.createContext({});

const LocalizationProvider = ({
  currentLocale,
  fallbackLocale,
  locales,
  localization,
  originalUrl,
  alternates,
  pluginOptions,
  children,
}) => {
  /**
   * Either checks for a matching alternate in page context or calls
   * default `translateUrl` method
   * @param {String} path
   * @param {String} locale
   * @returns {String}
   */
  const translateUrlWithAlternates = (path, locale) => {
    let pathMatchesAlternates = Object.values(alternates).some((alternate) => path === alternate);
    if (pathMatchesAlternates && alternates[locale]) {
      return `/${locale}${alternates[locale]}`;
    }
    return translateUrl({
      path,
      locale: locale || currentLocale || fallbackLocale,
      ...pluginOptions,
    });
  };

  return (
    <LocalizationContext.Provider
      value={{
        locale: currentLocale || fallbackLocale,
        locales: locales,
        localization: localization,
        originalUrl: originalUrl,
        translateUrl: translateUrlWithAlternates,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
export default LocalizationProvider;
