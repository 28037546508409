import React from "react";
import LocalizationProvider from "./context/LocalizationContext";

export const wrapPageElement = ({ element, props }, options) => {
  const locales = Object.keys(options.translations);
  const fallbackLocale = options.defaultLocale || locales[0] || "en";
  const { locale, localization, originalUrl, alternates = {} } = props.pageContext;

  return (
    <LocalizationProvider
      currentLocale={locale}
      fallbackLocale={fallbackLocale}
      locales={locales}
      localization={localization}
      originalUrl={originalUrl}
      alternates={alternates}
      pluginOptions={options}
    >
      {element}
    </LocalizationProvider>
  );
};
