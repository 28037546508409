/**
 * Checks if the current environment is a client / browser.
 * @returns {Boolean} true if it is client, false otherwise
 */
const isClient = () => {
  return (
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
  );
};

export default isClient;
