import { useStaticQuery, graphql } from "gatsby";

export const useAllStrapiCookie = (locale) => {
  const { allStrapiCookie } = useStaticQuery(
    graphql`
      query allStrapiCookie {
        allStrapiCookie {
          edges {
            node {
              id
              locale
              name
              slug
              type {
                slug
              }
            }
          }
        }
      }
    `,
  );

  if (locale) {
    return allStrapiCookie.edges.filter((item) => item.node.locale === locale);
  } else {
    return allStrapiCookie.edges;
  }
};
