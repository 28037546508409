import { useState, useEffect } from "react";
import isClient from "../utils/isClient";

const getSavedValue = (key, initalValue) => {
  if (!isClient()) return undefined;

  const regex = new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`);
  const cookie = document.cookie.replace(regex, "$1");

  if (cookie.length) return JSON.parse(cookie);

  if (initalValue instanceof Function) return initalValue();

  return initalValue;
};

/**
 * A custom hook that handles cookie management within the browser.
 * @param {String} name the name or key of the cookie
 * @param {*} initalValue the default value of the cookie. If left empty, the cookie will not be created.
 * @returns {Array} array including the cookie value to the provided key. Returns initalValue if not found.
 */
export const useCookie = ({ name, initalValue, expires, path }) => {
  const [value, setValue] = useState(() => {
    return getSavedValue(name, initalValue);
  });

  useEffect(() => {
    if (isClient() && value) {
      document.cookie = `${name}=${JSON.stringify(
        value,
      )}; expires=${expires}; path=${path}; secure`;
    }
  }, [name, value, expires, path]);

  return [value, setValue];
};
